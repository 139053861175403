import { isMobile } from '../utils/index'
export default {
  address: '接待中心：新北市新莊區昌德街33號旁',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3614.3847262875265!2d121.4549993!3d25.0549462!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x43f7405f5c2b6594!2zMjXCsDAzJzE3LjgiTiAxMjHCsDI3JzI1LjkiRQ!5e0!3m2!1szh-TW!2stw!4v1637550814856!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/THRXYmqYuF4Ava7a6',
  phone: '02-2276-7272',
  fbLink: 'https://www.facebook.com/105760468592626',
  fbMessage: 'https://m.me/105760468592626',
  caseName: '青青',
  indigatorLength: 10,

  houseInfos: [
    ['格局規劃', '16~31坪'],
    ['棟戶規劃', '戶數：131戶'],
    ['基地面績', '約487.66坪'],
    ['企劃設計', '海沃創意行銷'],
  ],

  gtmCode: ['MQCH8XK'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
